.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

#loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #436c9f;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
    z-index: 1001;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #2c365d;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #bebebe;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

@keyframes spin {
    0% {
        /* Chrome, Opera 15+, Safari 3.1+ */
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        /* Chrome, Opera 15+, Safari 3.1+ */
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

#loader-wrapper .loader-section {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.8);
    z-index: 1000;
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateX(0);
    /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
    left: 0;
}

#loader-wrapper .loader-section.section-right {
    right: 0;
}


/* Loaded */

.loaded #loader-wrapper .loader-section.section-left {
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateX(-100%);
    /* Firefox 16+, IE 10+, Opera */
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader-wrapper .loader-section.section-right {
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateX(100%);
    /* Firefox 16+, IE 10+, Opera */
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
    visibility: hidden;
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateY(-100%);
    /* Firefox 16+, IE 10+, Opera */
    transition: all 0.3s 1s ease-out;
}

#loader-wrapper .loader-text {
    position: absolute;
    z-index: 1024;
    left: 0;
    right: 0;
    top: 50%;
    visibility: visible;
    opacity: 1;
    margin-top: 120px;
    text-align: center;
}

#loader-wrapper .loader-text.section-text {
    color: #fff;
}

.loaded #loader-wrapper .loader-text{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}
#loader-wrapper .loader-text.section-text .section-caption {
    font-size: 14px;
}

.plan-card{
    padding: 0 6px;
    background: rgb(66,163,229);
    background: linear-gradient(0deg, rgba(66,163,229,1) 0%, rgba(6,1,44,1) 100%);
}
.MuiDialog-paperWidthSm{
    max-width: 1000px;
    height: fit-content;
    max-height: 600px;
    /* justify-content: space-evenly; */
}