@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

.welcome-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-image: url(/static/images/welcome-bg.png);
    background-color: #05002e;
    height: 100%;
    width: 100%;
    background-size: 100%;
    color: #FFF;
    overflow-x: hidden;
}

.slider-item-full {
    height: 55vh;
    text-align: center;
    outline: none;
    width: 100%;
    overflow: hidden;
    display: block;
}

.slide-half.slider-item-full {
    display: flex !important;
    flex-direction: row;
}

.slider-item-full img {
    margin: 0 auto;
}

.start-course-btn {
    background: none;
    color: white;
    margin: 20px auto;
    padding: 9px 30px;
    box-shadow: none;
    outline: none;
    border: 1px solid #FFF;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 15px;
    display: block;
    margin-top: 0;
}

.start-course-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
}


.welcome-root .slick-slider {
    width: 100%;
    height: 53vh;
}

@media (max-width: 1000px) {
    .welcome-root .slick-slider {
        width: 100%;
        height: 460px;
    }   
}

@media (max-width: 670px) {
    .welcome-root .slick-slider {
        width: 100%;
        height: 480px;
    }   
}

.welcome-root .slick-arrow.slick-next,.welcome-root .slick-arrow.slick-prev {
    height: 35px;
    cursor: pointer;
    z-index: 1;
}

.welcome-root .slick-arrow.slick-next {
    right: 50px;
    top: 36%;
}

.welcome-root .slick-arrow.slick-prev {
    left: 50px;
    top: 33%;
    transform: rotate(180deg);
    margin-top: -32px !important;
}

.welcome-root .slick-arrow.slick-next, .welcome-root .slick-arrow.slick-prev {
    opacity: 1;
    width: 30px;
    height: 80px !important;
}

.welcome-root .slick-arrow.slick-next:hover, .welcome-root .slick-arrow.slick-prev:hover {
    opacity: 1;
}

.welcome-root ul.slick-dots {
    position: relative;
    top: 60px;
    padding-bottom: 30px;
}

.welcome-root .slick-dots li button {
    color: white !important;
    border: 1px solid #FFF !important;
    border-radius: 50%;
    padding: 2px !important;
    text-align: center;
    overflow: hidden;
    width: 16px !important;
    height: 16px !important;
}

.welcome-root .slick-dots li.slick-active button:before {
    color: #FFF !important;
    opacity: 1;
    width: 17px !important;
    height: 17px !important;
    font-size: 18px !important;
    margin-top: 0px;
    margin-left: -1px;
}

.slider-half-item {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background-wave {
    width: 100vw;
    position:absolute;
    top: 45%;
    z-index: -1;
}

@media (max-width: 1000px) {
    .welcome-root {
        background-image: none;
    }

    .background-wave{
        left:-20px
    }

    .slider-half-item {
        width: 100%;
        align-items: center !important;
        padding: 0 !important;
    }

    .slide-half.slider-item-full .slider-half-item.text {
        margin-bottom: 30px;
        margin-top: 10px;
        text-align: center;
        align-items: center !important;
    }
    .slider-item-full .slider-half-item{
        align-items: center !important;
    }

    .slider-item-full img:first-child {
        width: 70%;
        height: auto;
    }

    .slider-item-full {
        padding: 0 20px;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .slide-half.slider-item-full .slider-half-item.text h4 {
        margin-top: 5px;
        margin-bottom: -5px;
    }

    .slide-half.slider-item-full {
        flex-direction: column;
        display: flex !important;
        height: 470px !important;
    }

    .slide1-wrapper .slider-item-full {
        height: 470px !important;
    }

    .full-width {
        width: 80% !important;
    }

    .welcome-root .slick-arrow.slick-next {
        right: 5px;
        top: 42%;
    }

    .welcome-root .slick-arrow.slick-prev {
        left: 5px;
        top: 43%;
        margin-top: -20px;
    }

    .aligncenter {
        text-align: center;
    }

    .vertical-image {
        height: 260px !important;
        width: auto !important;
    }

    .main-heading {
        font-size: 25px !important;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 30px !important;
    }

    img.no-margin {
        margin-top: 30px !important;
    }
}

@media (min-width: 1600px) {
    .welcome-root ul.slick-dots {
        top: 140px;
    }
}

.welcome-root .slick-prev.slick-disabled {
    display: block !important;
}

@media (max-width: 680px) {
    .welcome-root .slick-arrow.slick-next {
        top: 40%;
    }

    .welcome-root .slick-arrow.slick-prev {
        top: 41%;
    }
}
