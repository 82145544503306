@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
pre {
  border-radius: 4px;
  background-color: #191c27;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 24px
}

code[class*="language-"],
pre[class*="language-"] {
  color: rgb(191, 199, 213);
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 4;
  -webkit-hyphens: none;
          hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #1d1f21;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.prolog {
  color: rgb(0, 0, 128);
}

.token.parameter {
  color: rgb(255, 255, 255);
}

.token.comment {
  color: rgb(106, 153, 85);
}

.token.doctype {
  color: rgb(191, 199, 213);
}

.token.cdata {
  color: rgb(191, 199, 213);
}

.token.punctuation {
  color: rgb(136, 198, 190);
}

.token.property {
  color: rgb(252, 146, 158);
}

.token.tag {
  color: rgb(252, 146, 158);
}

.token.class-name {
  color: rgb(250, 200, 99);
}

.token.boolean {}

.token.constant {
  color: rgb(100, 102, 149);
}

.token.symbol {
  color: rgb(141, 200, 145);
}

.token.deleted {
  color: rgb(141, 200, 145);
}

.token.number {
  color: rgb(181, 206, 168);
}

.token.inserted {
  color: rgb(181, 206, 168);
}

.token.selector {
  color: rgb(215, 186, 125);
}

.token.char {
  color: rgb(209, 105, 105);
}

.token.builtin {
  color: rgb(197, 165, 197);
}

.token.changed {
  color: rgb(197, 165, 197);
}

.token.keyword {
  color: rgb(197, 165, 197);
}

.token.string{
  color: rgb(195, 232, 141);
}

.token.attr-name {
  color: rgb(156, 220, 254);
}

.token.variable {
  color: rgb(156, 220, 254);
}

.token.operator {
  color: #EDEDED;
}

.token.entity {
  color: #FFFFB6;
  cursor: help;
}

.token.url {
  color: #96CBFE;
}

.language-css .token.string,
.style .token.string {
  color: #87C38A;
}

.token.atrule,
.token.attr-value {
  color: #F9EE98;
}

.token.function {
  color: rgb(121, 182, 242)
}

.token.regex {
  color: #E9C062;
}

.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}
.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

#loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #436c9f;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
    z-index: 1001;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #2c365d;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #bebebe;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

@keyframes spin {
    0% {
        /* Chrome, Opera 15+, Safari 3.1+ */
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        /* Chrome, Opera 15+, Safari 3.1+ */
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

#loader-wrapper .loader-section {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.8);
    z-index: 1000;
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateX(0);
    /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
    left: 0;
}

#loader-wrapper .loader-section.section-right {
    right: 0;
}


/* Loaded */

.loaded #loader-wrapper .loader-section.section-left {
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateX(-100%);
    /* Firefox 16+, IE 10+, Opera */
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader-wrapper .loader-section.section-right {
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateX(100%);
    /* Firefox 16+, IE 10+, Opera */
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
    visibility: hidden;
    /* Chrome, Opera 15+, Safari 3.1+ */
    /* IE 9 */
    transform: translateY(-100%);
    /* Firefox 16+, IE 10+, Opera */
    transition: all 0.3s 1s ease-out;
}

#loader-wrapper .loader-text {
    position: absolute;
    z-index: 1024;
    left: 0;
    right: 0;
    top: 50%;
    visibility: visible;
    opacity: 1;
    margin-top: 120px;
    text-align: center;
}

#loader-wrapper .loader-text.section-text {
    color: #fff;
}

.loaded #loader-wrapper .loader-text{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}
#loader-wrapper .loader-text.section-text .section-caption {
    font-size: 14px;
}

.plan-card{
    padding: 0 6px;
    background: rgb(66,163,229);
    background: linear-gradient(0deg, rgba(66,163,229,1) 0%, rgba(6,1,44,1) 100%);
}
.MuiDialog-paperWidthSm{
    max-width: 1000px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 600px;
    /* justify-content: space-evenly; */
}
.welcome-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-image: url(/static/images/welcome-bg.png);
    background-color: #05002e;
    height: 100%;
    width: 100%;
    background-size: 100%;
    color: #FFF;
    overflow-x: hidden;
}

.slider-item-full {
    height: 55vh;
    text-align: center;
    outline: none;
    width: 100%;
    overflow: hidden;
    display: block;
}

.slide-half.slider-item-full {
    display: flex !important;
    flex-direction: row;
}

.slider-item-full img {
    margin: 0 auto;
}

.start-course-btn {
    background: none;
    color: white;
    margin: 20px auto;
    padding: 9px 30px;
    box-shadow: none;
    outline: none;
    border: 1px solid #FFF;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 15px;
    display: block;
    margin-top: 0;
}

.start-course-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
}


.welcome-root .slick-slider {
    width: 100%;
    height: 53vh;
}

@media (max-width: 1000px) {
    .welcome-root .slick-slider {
        width: 100%;
        height: 460px;
    }   
}

@media (max-width: 670px) {
    .welcome-root .slick-slider {
        width: 100%;
        height: 480px;
    }   
}

.welcome-root .slick-arrow.slick-next,.welcome-root .slick-arrow.slick-prev {
    height: 35px;
    cursor: pointer;
    z-index: 1;
}

.welcome-root .slick-arrow.slick-next {
    right: 50px;
    top: 36%;
}

.welcome-root .slick-arrow.slick-prev {
    left: 50px;
    top: 33%;
    transform: rotate(180deg);
    margin-top: -32px !important;
}

.welcome-root .slick-arrow.slick-next, .welcome-root .slick-arrow.slick-prev {
    opacity: 1;
    width: 30px;
    height: 80px !important;
}

.welcome-root .slick-arrow.slick-next:hover, .welcome-root .slick-arrow.slick-prev:hover {
    opacity: 1;
}

.welcome-root ul.slick-dots {
    position: relative;
    top: 60px;
    padding-bottom: 30px;
}

.welcome-root .slick-dots li button {
    color: white !important;
    border: 1px solid #FFF !important;
    border-radius: 50%;
    padding: 2px !important;
    text-align: center;
    overflow: hidden;
    width: 16px !important;
    height: 16px !important;
}

.welcome-root .slick-dots li.slick-active button:before {
    color: #FFF !important;
    opacity: 1;
    width: 17px !important;
    height: 17px !important;
    font-size: 18px !important;
    margin-top: 0px;
    margin-left: -1px;
}

.slider-half-item {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background-wave {
    width: 100vw;
    position:absolute;
    top: 45%;
    z-index: -1;
}

@media (max-width: 1000px) {
    .welcome-root {
        background-image: none;
    }

    .background-wave{
        left:-20px
    }

    .slider-half-item {
        width: 100%;
        align-items: center !important;
        padding: 0 !important;
    }

    .slide-half.slider-item-full .slider-half-item.text {
        margin-bottom: 30px;
        margin-top: 10px;
        text-align: center;
        align-items: center !important;
    }
    .slider-item-full .slider-half-item{
        align-items: center !important;
    }

    .slider-item-full img:first-child {
        width: 70%;
        height: auto;
    }

    .slider-item-full {
        padding: 0 20px;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .slide-half.slider-item-full .slider-half-item.text h4 {
        margin-top: 5px;
        margin-bottom: -5px;
    }

    .slide-half.slider-item-full {
        flex-direction: column;
        display: flex !important;
        height: 470px !important;
    }

    .slide1-wrapper .slider-item-full {
        height: 470px !important;
    }

    .full-width {
        width: 80% !important;
    }

    .welcome-root .slick-arrow.slick-next {
        right: 5px;
        top: 42%;
    }

    .welcome-root .slick-arrow.slick-prev {
        left: 5px;
        top: 43%;
        margin-top: -20px;
    }

    .aligncenter {
        text-align: center;
    }

    .vertical-image {
        height: 260px !important;
        width: auto !important;
    }

    .main-heading {
        font-size: 25px !important;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 30px !important;
    }

    img.no-margin {
        margin-top: 30px !important;
    }
}

@media (min-width: 1600px) {
    .welcome-root ul.slick-dots {
        top: 140px;
    }
}

.welcome-root .slick-prev.slick-disabled {
    display: block !important;
}

@media (max-width: 680px) {
    .welcome-root .slick-arrow.slick-next {
        top: 40%;
    }

    .welcome-root .slick-arrow.slick-prev {
        top: 41%;
    }
}

* {
    font-family: 'Poppins', sans-serif !important;
}

.MuiDialog-root {
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px); /* Apply background blur */
}
.task-response input[type=text]{
color: #fff;
}

.MuiPopover-paper::-webkit-scrollbar {
  width: 5px;
}

.MuiPopover-paper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.MuiPopover-paper::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  outline: 1px solid slategrey;
}

.zsiq_floatmain {
  display: none !important;
}

.zsiq_floatmain.visible {
  display: block !important;
  height: 60px !important;
  z-index: 1000 !important;
  margin-right: 15px;
}
@media (min-width: 645px) {
  .zls-sptwndw.zsiq-newtheme {
    z-index: 1000 !important;
  }
}
@media (max-width: 645px) {
  .hide-chatbot .zsiq_floatmain.visible{
    display: none !important;
  }
  div#titlediv {
    display: none;
  }
}
#main-scrollbar .ps__rail-x, #main-scrollbar .ps__rail-y {
  opacity: 0.6;
}
.hide-promotions .promotion-item{
  display: none;
}

.cnx-main-container{
  border-radius: 10px;
}
